<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">หน้าสรุปบัญชี</h4>
      </div>
      <v-data-table
        :items="data"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:header="" >
        <thead class="v-data-table-header">
          <tr>
            <td colspan="1" class="text-center">SENDER</td>
            <td colspan="1" class="text-center">CODE</td>
            <td colspan="1" class="text-center">HN</td>
            <td colspan="1" class="text-center">รายการ</td>
            <td colspan="1" class="text-center">REMARK</td>
            <td colspan="1" class="text-center">หมายเหตุ</td>
            <td colspan="1" class="text-center">เงินสด</td>
            <td colspan="2" class="text-center">บริษัท</td>
            <td colspan="2" class="text-center">กรุงศรี</td>
            <td colspan="2" class="text-center">ธนชาติ</td>
            <td colspan="4" class="text-center">บัตร</td>
            <td colspan="1" class="text-center">DR.</td>
            <td colspan="3" class="text-center">BT</td>
          </tr>
        </thead>
        <tr>
          <td class="text-center thead-summary-style"></td>
          <td class="text-center thead-summary-style"></td>
          <td class="text-center thead-summary-style"></td>
          <td class="text-center thead-summary-style"></td>
          <td class="text-center thead-summary-style"></td>
          <td class="text-center thead-summary-style"></td>
          <td class="text-center thead-summary-style"></td>
          <td class="text-center thead-summary-style">ยอดเงิน</td>
          <td class="text-center thead-summary-style">เวลา</td>
          <td class="text-center thead-summary-style">ยอดเงิน</td>
          <td class="text-center thead-summary-style">เวลา</td>
          <td class="text-center thead-summary-style">ยอดเงิน</td>
          <td class="text-center thead-summary-style">เวลา</td>
          <td class="text-center thead-summary-style">กสิกร</td>
          <td class="text-center thead-summary-style">กรุงศรี</td>
          <td class="text-center thead-summary-style">กรุงไทย</td>
          <td class="text-center thead-summary-style">ไทยพานิชย์</td>
          <td class="text-center thead-summary-style"></td>
          <td class="text-center thead-summary-style">ช่วยหมอ</td>
          <td class="text-center thead-summary-style">ทำเอง</td>
          <td class="text-center thead-summary-style">เครื่อง</td>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center">{{item.sender.name || '-' }}</td>
          <td class="text-center">{{item.code || '-' }}</td>
          <td class="text-center">{{item.list.hn_code || '-' }}</td>
          <td class="text-start" style="min-width: 15rem">{{item.list.prepaid ? `มัดจำ ${item.list.prepaid} คุณ${item.list.name}` : item.list.name || '-' }}</td>
          <td class="text-start" style="min-width: 7rem">{{item.list.remark || '-' }}</td>
          <td class="text-start" style="min-width: 7rem">{{item.list.note || '-' }}</td>
          <td class="text-end" style="min-width: 5rem">{{item.cash || '-' }}</td>
          <td class="text-end" style="min-width: 5rem">{{item.company.total || '-' }}</td>
          <td class="text-start" style="min-width: 5rem">{{item.company.total ? (item.company.datetime).format('HH:mm') : '-' }}</td>
          <td class="text-end" style="min-width: 5rem">{{item.bank.kma.total || '-' }}</td>
          <td class="text-start" style="min-width: 5rem">{{item.bank.kma.total ? (item.bank.kma.datetime).format('HH:mm') : '-' }}</td>
          <td class="text-end" style="min-width: 5rem">{{item.bank.tbank.total || '-' }}</td>
          <td class="text-start" style="min-width: 5rem">{{item.bank.tbank.total ? (item.bank.tbank.datetime).format('HH:mm') : '-' }}</td>
          <td class="text-end" style="min-width: 5rem">{{item.bank.credit.kbank || '-' }}</td>
          <td class="text-end" style="min-width: 5rem">{{item.bank.credit.kma || '-' }}</td>
          <td class="text-end" style="min-width: 5rem">{{item.bank.credit.ktb || '-' }}</td>
          <td class="text-end" style="min-width: 5rem">{{item.bank.credit.tbank || '-' }}</td>
          <td class="text-start" style="min-width: 5rem">{{item.doctor.name || '-' }}</td>
          <td class="text-start" style="min-width: 5rem">{{item.assistance.name || '-' }}</td>
          <td class="text-start" style="min-width: 5rem">{{!item.assistance.name ? 'ทำเอง' : '-'}}</td>
          <td class="text-start" style="min-width: 5rem">{{item.assistance.machine || '-' }}</td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      data: [{
        _id: '000001',
        sender: {
          _id: '001',
          name: 'A'
        },
        code: '',
        list: {
          _id: '0001',
          hn_code: 'ST631533',
          name: 'สมพร นาทองหลาย',
          prepaid: 1000,
          remark: 'มัดจำ 1,000',
          note: 'ยังไม่เลือกโปร'
        },
        cash: 0,
        company: {
          total: 0,
          datetime: moment()
        },
        bank: {
          kma: {
            total: 0,
            datetime: moment()
          },
          tbank: {
            total: 1000,
            datetime: moment()
          },
          credit: {
            kbank: 0,
            kma: 0,
            ktb: 0,
            tbank: 0
          }
        },
        doctor: {
          _id: '01',
          name: ''
        },
        assistance: {
          _id: '00000001',
          name: 'T',
          machine: ''
        }
      }, {
        _id: '000002',
        sender: {
          _id: '002',
          name: 'A'
        },
        code: '',
        list: {
          _id: '0002',
          hn_code: 'ST631535',
          name: 'กรทิพย์ สิทธิกูล',
          prepaid: 1000,
          remark: 'มัดจำ 1,000',
          note: 'สนใจร้อยไหม 4,900'
        },
        cash: 0,
        company: {
          total: 0,
          datetime: moment()
        },
        bank: {
          kma: {
            total: 0,
            datetime: moment()
          },
          tbank: {
            total: 1000,
            datetime: moment()
          },
          credit: {
            kbank: 0,
            kma: 0,
            ktb: 0,
            tbank: 0
          }
        },
        doctor: {
          _id: '02',
          name: ''
        },
        assistance: {
          _id: '00000002',
          name: 'T',
          machine: ''
        }
      }, {
        _id: '000003',
        sender: {
          _id: '003',
          name: 'R'
        },
        code: '',
        list: {
          _id: '0003',
          hn_code: 'ST631539',
          name: 'กรกนก พิกุล',
          prepaid: 1000,
          remark: 'มัดจำ 1,000',
          note: 'สนใจฟิลเล่อร์ใต้ตา 13,999'
        },
        cash: 0,
        company: {
          total: 0,
          datetime: moment()
        },
        bank: {
          kma: {
            total: 0,
            datetime: moment()
          },
          tbank: {
            total: 1000,
            datetime: moment()
          },
          credit: {
            kbank: 0,
            kma: 0,
            ktb: 0,
            tbank: 0
          }
        },
        doctor: {
          _id: '03',
          name: ''
        },
        assistance: {
          _id: '00000003',
          name: 'T',
          machine: ''
        }
      }, {
        _id: '000004',
        sender: {
          _id: '',
          name: ''
        },
        code: 'B',
        list: {
          _id: '0004',
          hn_code: 'ST631534',
          name: 'ร้อยไหมทอร์ 6 เส้น',
          prepaid: 1000,
          remark: 'มัดจำ 1,000',
          note: 'สนใจร้อยไหม'
        },
        cash: 0,
        company: {
          total: 0,
          datetime: moment()
        },
        bank: {
          kma: {
            total: 12998,
            datetime: moment()
          },
          tbank: {
            total: 0,
            datetime: moment()
          },
          credit: {
            kbank: 0,
            kma: 0,
            ktb: 0,
            tbank: 0
          }
        },
        doctor: {
          _id: '04',
          name: 'K'
        },
        assistance: {
          _id: '00000004',
          name: 'M',
          machine: ''
        }
      }, {
        _id: '000004',
        sender: {
          _id: '',
          name: ''
        },
        code: 'W',
        list: {
          _id: '0004',
          hn_code: 'ST0011',
          name: 'Made',
          prepaid: 1000,
          remark: '',
          note: 'สนใจรักษาหลุมสิว'
        },
        cash: 17998,
        company: {
          total: 0,
          datetime: moment()
        },
        bank: {
          kma: {
            total: 31997,
            datetime: moment()
          },
          tbank: {
            total: 0,
            datetime: moment()
          },
          credit: {
            kbank: 0,
            kma: 0,
            ktb: 0,
            tbank: 0
          }
        },
        doctor: {
          _id: '04',
          name: 'K'
        },
        assistance: {
          _id: '00000004',
          name: 'T',
          machine: ''
        }
      }]
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'sender'
        }, {
          text: 'Code',
          align: 'center',
          sortable: false,
          value: 'code'
        }, {
          text: 'HN',
          align: 'center',
          value: 'list',
          sortable: false
        }, {
          text: 'รายการ',
          align: 'center',
          value: 'list',
          sortable: false
        }, {
          text: 'REMARK',
          align: 'center',
          value: 'list',
          sortable: false
        }, {
          text: 'หมายเหตุ',
          align: 'center',
          value: 'list',
          sortable: false
        }, {
          text: 'เงินสด',
          align: 'center',
          value: 'cash',
          sortable: false
        }, {
          text: 'บริษัท',
          align: 'center',
          value: 'company',
          sortable: false
        }, {
          text: 'กรุงศรี',
          align: 'center',
          value: 'bank',
          sortable: false
        }, {
          text: 'ธนชาติ',
          align: 'center',
          value: 'bank',
          sortable: false
        }, {
          text: 'บัตร',
          align: 'center',
          value: 'bank',
          sortable: false
        }, {
          text: 'DR.',
          align: 'center',
          value: 'doctor',
          sortable: false
        }, {
          text: 'BT',
          align: 'center',
          value: 'assistance',
          sortable: false
        }
      ]
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style>
.thead-summary-style {
  border-bottom: 1px solid rgba(226, 226, 226, 0.87) !important;
  padding: 0 0.125rem 0.25rem 0.125rem !important;
}
</style>
